html, body
{
    height: 0%;
    background-color: rgb(39, 39, 39);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* nav bar */
.navbar {
  background-color: rgb(39, 39, 39); 
  border-bottom: rgb(253, 22, 98);
  border-width: 3px;
  border-style: solid;
}

.nav-links{
  color: #fff !important;
} 
.nav-links:visited{
  color: #fff !important;
}

.nav-links:active{
  color: #FD1662 !important;
}

.nav-links:hover {
  color: #FD1662 !important; /*will change color of text within the element on hover */
}


.header{
  background-color: rgb(39, 39, 39); 
}

/* content */
container{
  width: 1800px;
} 

.content{
  background-color: rgb(39, 39, 39); 
  color:#FFFFFFDE;
  padding: 10px;
}



.button:hover {
  color: #FFFFFFDE !important;
  border-color: #FD1662 !important;
  background-color: #FD1662 !important ;
}




    /* up: 'rgba(40, 167, 69, 0.87)',
    down: 'rgba(220, 53, 69, 0.87)',
    textHigh: '#FFFFFFDE', // 'rgba(255,255,255,0.87)',
    textMedium: '#FFFFFF99', //rgba(255,255,255,0.60)',
    textDisabled: '#FFFFFF61', //rgba(255,255,255,0.38)',
    textHighReverse: 'rgba(0,0,0,0.87)',
    textMediumReverse: 'rgba(0,0,0,0.60)',
    textDisabledReverse: 'rgba(0,0,0,0.38)',
    primaryColor: 'rgb(253, 22, 98)', //'#FD1662'
    primaryColorDisable: 'rgb(255, 96, 144)',
    secondaryColor: '#FFFFFFDE',
    secondaryColorDisable: '#FFFFFF61',
    background: 'rgb(39, 39, 39)',
    amountBtn: '#FFFFFFDE',
    area: 'rgba(84, 84, 84, 0.87)',
    areaReverse: 'rgba(0, 0, 0, 0.6)',
    infoColor: '#D07D00',
    blackColor: 'rgba(0,0,0,0.87)',
    border: 'rgba(255,255,255,0.05)',
    trackColorFalse: '#767577',
    trackColorTrue: 'rgba(194, 0, 58, 0.38)', */